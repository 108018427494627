<template>
  <div>
    <v-card class="mb-8">
      <v-row class="px-2 ma-0 pb-2 text-center">
        <v-col
          cols="12"
          md="2"
        >
          <v-select
            v-model="dateRangeFilter"
            :items="dateRangeOptions"
            item-text="text"
            item-value="value"
            single-line
            outlined
            dense
            hide-details
            :placeholder="t('select.date_range')"
          ></v-select>
        </v-col>

        <!-- from date filter -->
        <v-col
          v-if="dateRangeFilter === 'C'"
          cols="12"
          md="3"
          class="pb-0"
        >
          <v-menu
            v-model="modalFrom"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateFrom"
                :placeholder="t('transactions.from_date')"
                :prepend-icon="icons.mdiCalendarArrowRight"
                outlined
                readonly
                dense
                clearable
                class="invoice-list-status"
                hide-details
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateFrom"
              color="primary"
              :min="minDate"
              :max="nowDate"
              @input="modalFrom = false"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <!-- to date filter -->
        <v-col
          v-if="dateRangeFilter === 'C'"
          cols="12"
          md="3"
          class="pb-0"
        >
          <v-menu
            v-model="modalTo"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateTo"
                :placeholder="t('transactions.to_date')"
                :prepend-icon="icons.mdiCalendarArrowLeft"
                outlined
                readonly
                dense
                clearable
                class="invoice-list-status"
                hide-details
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateTo"
              color="primary"
              :min="dateFrom"
              :max="nowDate"
              @input="modalTo = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-card>
    <insights-component
      :data-fecha-inicio="startDate"
      :data-fecha-fin="endDate"
      :data-group="dataComputedGroup"
      :data-facility="dataComputedFacility"
    >
    </insights-component>
  </div>
</template>
<script>

import { ref, watch, computed } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import {
  getInsights,
} from '@api'
import
{
  mdiCalendarArrowLeft, mdiCalendarArrowRight, mdiHelp, mdiFocusField, mdiCloseCircleOutline, mdiTableCancel, mdiSchoolOutline, mdiCalendarToday, mdiRepeat, mdiArrowDownDropCircleOutline, mdiCalendarCheck, mdiGuitarPickOutline, mdiCurrencyUsd,
} from '@mdi/js'
import InsightsComponent from '../insights/InsightsComponent.vue'

export default {
  components: {
    InsightsComponent,
  },
  props: {
    dataGroup: {
      type: Number,
      default: () => 0,
    },
    dataFacility: {
      type: Number,
      default: () => 0,
    },
  },
  setup(props) {
    const startDate = ref('')
    const endDate = ref('')
    const items1 = [
      { text: 'Locker room reservations', icon: 'mdi-clock', total: '22' },
      { text: 'Groups confirmed (100%)', icon: 'mdi-account', total: '12' },
    ]
    const items2 = [
      { text: 'Game invites send', icon: 'mdi-clock', total: '190' },
      { text: 'Expiration reminder', icon: 'mdi-account', total: '50' },
      { text: 'Prioruty "2" game invites', icon: 'mdi-clock', total: '40' },
      { text: 'Guest players invites', icon: 'mdi-account', total: '13' },
    ]
    const dataComputedGroup = computed(() => (props.dataGroup ? props.dataGroup : 0))
    const dataComputedFacility = computed(() => (props.dataFacility ? props.dataFacility : 0))
    const { t } = useUtils()
    const modalFrom = ref(null)
    const modalTo = ref(null)
    const minDate = ref(
      new Date('2021-01-02').toLocaleString('sv-SE', {
        timeZone: 'America/New_York',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
    )
    const maxDate = ref(
      new Date(+new Date() + 12096e5).toLocaleString('sv-SE', {
        timeZone: 'America/New_York',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
    )
    const nowDate = ref(
      new Date().toLocaleString('sv-SE', {
        timeZone: 'America/New_York',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
    )
    const dateFrom = ref(nowDate.value)
    const dateTo = ref(nowDate.value)
    const dateRangeFilter = ref('')
    const dateRangeOptions = ref([
      { text: t('date_range.this_year'), value: 'TY' },
      { text: t('date_range.this_month'), value: 'TM' },
      { text: t('date_range.last_month'), value: 'LM' },
      { text: t('date_range.this_week'), value: 'TW' },
      { text: t('date_range.last_week'), value: 'LW' },
      { text: t('date_range.custom'), value: 'C' },
    ])
    const fieldsReservation1 = {
      statTitle: 'Field 1',
      statistics: '12',
      icon: mdiFocusField,
      color: 'secondary',
      subtitle: '$1440',
      background: '#353232',
    }

    const fieldsReservation2 = {
      statTitle: 'Field 2',
      statistics: '12',
      icon: mdiFocusField,
      color: 'secondary',
      subtitle: '$1440',
      background: '#353232',
    }

    const fieldsReservation3 = {
      statTitle: 'Field 3',
      statistics: '12',
      icon: mdiFocusField,
      color: 'secondary',
      subtitle: '$1440',
      background: '#353232',
    }

    const fieldsReservation4 = {
      statTitle: 'Field 4',
      statistics: '12',
      icon: mdiFocusField,
      color: 'secondary',
      subtitle: '$1440',
      background: '#353232',
    }

    const fieldsReservation5 = {
      statTitle: 'Field 5',
      statistics: '12',
      icon: mdiFocusField,
      color: 'secondary',
      subtitle: '$1440',
      background: '#353232',
    }

    const fieldsReservation6 = {
      statTitle: 'Field 6',
      statistics: '12',
      icon: mdiFocusField,
      color: 'secondary',
      subtitle: '$1440',
      background: '#353232',
    }

    const newCustomerOptions = {
      statTitle: 'Total Reservations',
      statistics: '2,856',
      weekoverweek: 'week over week',
      change: '-10.2%',
      icon: mdiCalendarCheck,
      color: 'white',
      total: '26',
    }

    // transparent background card
    const totalRevenueOptions = {
      statTitle: 'Paid',
      statistics: '28.6k',
      change: '+25.8%',
      icon: mdiCurrencyUsd,
      color: 'success',
      total: '26',
    }

    // transparent background card
    const newTransactions = {
      statTitle: 'Lost',
      statistics: '13.6k',
      change: '-25.8%',
      icon: mdiArrowDownDropCircleOutline,
      color: 'red',
      total: '26',
    }
    const newProjectOptions = {
      statTitle: 'Recurring',
      color: 'success',
      subtitle: '$ 1440',
      statistics: '12',
      change: '-18%',
      icon: mdiRepeat,
    }

    const totalProfitOptions = {
      statTitle: 'One day',
      icon: mdiCalendarToday,
      color: 'purple',
      subtitle: '$600',
      statistics: '5',
      change: '+42%',
    }

    const revenueOptions = {
      statTitle: 'Pick ups',
      icon: mdiGuitarPickOutline,
      color: 'warning',
      subtitle: '$240',
      statistics: '2',
      change: '+12%',
    }

    const logisticsOptions = {
      statTitle: 'Academies',
      icon: mdiSchoolOutline,
      color: 'blue',
      subtitle: '$120',
      statistics: '1',
      change: '+12%',
    }

    const reportsOptions = {
      statTitle: 'Cancellations',
      icon: mdiTableCancel,
      color: 'primary',
      subtitle: '$480',
      statistics: '4',
      change: '-8%',
    }

    const transactionsOptions = {
      statTitle: 'No Shows',
      icon: mdiCloseCircleOutline,
      color: 'error',
      subtitle: '$240',
      statistics: '2',
      change: '+12%',
    }
    const ratingsOptions = {
      statTitle: 'Ratings',
      statistics: '13k',
      change: '+38%',
      chipColor: 'primary',
    }

    watch([dateRangeFilter, dateFrom, dateTo], async newVal => {
      const currentDate = new Date()
      if (newVal[0] === 'TW') {
        const currentDayOfWeek = currentDate.getDay()
        const startOfWeek = new Date(currentDate)
        startOfWeek.setDate(currentDate.getDate() - currentDayOfWeek)

        const endOfWeek = new Date(currentDate)
        endOfWeek.setDate(startOfWeek.getDate() + 6)

        const [start1] = startOfWeek.toISOString().split('T')
        const [end1] = endOfWeek.toISOString().split('T')
        startDate.value = start1
        endDate.value = end1
      } else if (newVal[0] === 'TY') {
        const currentYear = new Date().getFullYear()
        const startOfYear = new Date(currentYear, 0, 1)
        const endOfYear = new Date(currentYear, 11, 31)

        const [start1] = startOfYear.toISOString().split('T')
        const [end1] = endOfYear.toISOString().split('T')
        startDate.value = start1
        endDate.value = end1
      } else if (newVal[0] === 'TM') {
        const currentYear = currentDate.getFullYear()
        const currentMonth = currentDate.getMonth()
        const startOfMonth = new Date(currentYear, currentMonth, 1)
        const endOfMonth = new Date(currentYear, currentMonth + 1, 0)

        const [start1] = startOfMonth.toISOString().split('T')
        const [end1] = endOfMonth.toISOString().split('T')
        startDate.value = start1
        endDate.value = end1
      } else if (newVal[0] === 'LM') {
        const currentYear = currentDate.getFullYear()
        const currentMonth = currentDate.getMonth()

        const previousYear = currentMonth === 0 ? currentYear - 1 : currentYear
        const previousMonth = currentMonth === 0 ? 11 : currentMonth - 1
        const startOfPreviousMonth = new Date(previousYear, previousMonth, 1)
        const endOfPreviousMonth = new Date(currentYear, currentMonth, 0)

        const [start1] = startOfPreviousMonth.toISOString().split('T')
        startDate.value = start1

        const [end1] = endOfPreviousMonth.toISOString().split('T')
        endDate.value = end1
      } else if (newVal[0] === 'LW') {
        const fechaActual = new Date()
        const diaSemana = fechaActual.getDay()
        const diasRetroceder = (diaSemana === 0) ? 6 : diaSemana - 1
        const inicioSemanaAnterior = new Date(fechaActual)
        inicioSemanaAnterior.setDate(fechaActual.getDate() - diasRetroceder)
        const finSemanaAnterior = new Date(fechaActual)
        finSemanaAnterior.setDate(fechaActual.getDate() - diasRetroceder - 1)

        const [start1] = inicioSemanaAnterior.toISOString().split('T')
        startDate.value = start1
        const [end1] = finSemanaAnterior.toISOString().split('T')
        endDate.value = end1
      } else if (newVal[0] === 'C') {
        startDate.value = dateFrom.value
        startDate.value = dateTo.value
      }

      await getInsights(dataComputedGroup.value, dataComputedFacility.value, startDate.value, endDate.value)
    })

    return {
      endDate,
      startDate,
      items1,
      items2,
      dateRangeFilter,
      newCustomerOptions,
      totalRevenueOptions,
      newTransactions,
      newProjectOptions,
      totalProfitOptions,
      reportsOptions,
      logisticsOptions,
      revenueOptions,
      transactionsOptions,
      ratingsOptions,
      fieldsReservation1,
      fieldsReservation2,
      fieldsReservation3,
      fieldsReservation4,
      fieldsReservation5,
      fieldsReservation6,
      dateRangeOptions,
      modalFrom,
      dateFrom,
      modalTo,
      dateTo,
      minDate,
      maxDate,
      nowDate,
      t,
      dataComputedGroup,
      dataComputedFacility,
      icons: {
        mdiCalendarArrowLeft,
        mdiCalendarArrowRight,
        mdiHelp,
      },
    }
  },
}
</script>
