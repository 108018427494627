<template>
  <div>
    <table>
      <tr>
        <th>UPA</th>
        <th>TIME</th>
        <th>
          <label for="chk1">MON</label><input
            id="chk1"
            style="margin-left: 10px;"
            type="checkbox"
            @click="handleCheckboxClick(1, $event)"
          >
        </th>
        <th>
          <label for="chk2">TUE</label><input
            id="chk2"
            style="margin-left: 10px;"
            type="checkbox"
            @click="handleCheckboxClick(2, $event)"
          >
        </th>
        <th>
          <label for="chk3">WED</label><input
            id="chk3"
            style="margin-left: 10px;"
            type="checkbox"
            @click="handleCheckboxClick(3, $event)"
          >
        </th>
        <th>
          <label for="chk4">THU</label><input
            id="chk4"
            style="margin-left: 10px;"
            type="checkbox"
            @click="handleCheckboxClick(4, $event)"
          >
        </th>
        <th>
          <label for="chk5">FRI</label><input
            id="chk5"
            style="margin-left: 10px;"
            type="checkbox"
            @click="handleCheckboxClick(5, $event)"
          >
        </th>
        <th>
          <label for="chk6">SAT</label><input
            id="chk6"
            style="margin-left: 10px;"
            type="checkbox"
            @click="handleCheckboxClick(6, $event)"
          >
        </th>
        <th>
          <label for="chk7">SUN</label><input
            id="chk7"
            style="margin-left: 10px;"
            type="checkbox"
            @click="handleCheckboxClick(7, $event)"
          >
        </th>
      </tr>
      <tr
        v-for="(row, indexr) in tableData"
        :key="indexr"
      >
        <td>
          <label for="chk8"></label><input
            id="chk8"
            v-model="row.checked"
            type="checkbox"
            @click="handleCheckRowClick"
          >
        </td>
        <td>{{ row.time }}</td>
        <td
          v-for="(value, indexc) in row.values"
          :key="indexc"
        >
          <v-card>
            <v-card-title class="align-start flex-nowrap">
              <div>
                <p>
                  <span class="text-2xl font-weight-semibold me-1">{{ value }}</span>
                </p>
              </div>
              <v-spacer></v-spacer>
              <v-menu>
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    small
                    class="mt-n2 me-n3"
                    v-on="on"
                  >
                    <v-icon size="22">
                      {{ icons.mdiDotsVertical }}
                    </v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-for="(item, i) in itemsm"
                    :key="i"
                    @click="onClickList(i, indexr, indexc)"
                  >
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-card-title>
          </v-card>
        </td>
      </tr>
    </table>
    <reservation-pickup-new
      :is-open.sync="isReservationNewOpen"
      :data-params="reservationNewData"
      :fields="fieldsOptions"
      :date="dateStr"
      @openClose="openCloseNew"
    ></reservation-pickup-new>
  </div>
</template>

<script>
import {
  ref,
  computed,
  watch,
  onMounted,
} from '@vue/composition-api'
import { getAllPicks, getFieldsByFacility } from '@api'
import { mdiDotsVertical } from '@mdi/js'
import ReservationPickupNew from './ReservationPickupNew.vue'

export default {
  components: {
    ReservationPickupNew,
  },
  props: {
    dataPicks: {
      type: Array,
      default: () => [],
    },
    dataGroup: {
      type: Number,
      default: () => 0,
    },
    dataFacility: {
      type: Number,
      default: () => 0,
    },
  },
  setup(props, { emit }) {
    const days = [false, false, false, false, false, false, false]
    const itemsm = ref([
      { title: 'Create PickUp Reservation' },
      { title: 'Export List' },
    ])
    const tableData = ref([])
    const fieldsOptions = ref([])
    const dataComputedPick = computed(() => (props.dataPicks ? props.dataPicks : []))
    const dataComputedGroup = computed(() => (props.dataGroup ? props.dataGroup : 0))
    const dataComputedFacility = computed(() => (props.dataFacility ? props.dataFacility : 0))
    const reservationNewData = ref({ group_id: '' })
    const isReservationNewOpen = ref(false)
    const dateStr = ref('')

    const fetchFields = async facilityId => {
      const fields = await getFieldsByFacility(facilityId)
      fieldsOptions.value = fields.data
    }

    const openCloseNew = value => {
      isReservationNewOpen.value = value
    }

    const getNextDayOfWeek = (date, dayOfWeek) => {
      const daysUntilNextDay = (dayOfWeek - date.getDay() + 7) % 7
      const nextDay = new Date(date)
      nextDay.setDate(date.getDate() + daysUntilNextDay)

      const year = nextDay.getFullYear()
      const month = String(nextDay.getMonth() + 1).padStart(2, '0')
      const day = String(nextDay.getDate()).padStart(2, '0')

      const formattedDate = `${year}-${month}-${day}`

      return formattedDate
    }

    const onClickList = async (i, rowi, coli) => {
      const rows = document.querySelectorAll('tr')
      const cells = rows[rowi + 1].querySelectorAll('td')
      if (i === 0) {
        const today = new Date()
        const newDate = getNextDayOfWeek(today, coli < 6 ? coli + 1 : 0)
        await fetchFields(dataComputedFacility.value)
        dateStr.value = newDate
        reservationNewData.value.group_id = dataComputedGroup.value
        reservationNewData.value.reservation_type = 'pick_up'
        reservationNewData.value.start_time = cells[1].innerHTML
        reservationNewData.value.reservation_date = newDate
        reservationNewData.value.facility_id = dataComputedFacility.value
        reservationNewData.value.circle_id = null
      }
      openCloseNew(true)
    }

    const getRandomInt = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min

    const generateTableData = () => {
      tableData.value = []
      let conta = 0
      for (let i = 0; i <= 24; i += 1) {
        const hour = i.toString().padStart(2, '0')
        let values = []
        for (let j = 0; j <= 6; j += 1) {
          values.push(dataComputedPick.value[j].values[conta])
        }
        tableData.value.push({
          time: `${hour}:00`,
          values,
          checked: false,
        })
        if (i < 23) {
          conta += 1
          values = []
          for (let j = 0; j <= 6; j += 1) {
            values.push(dataComputedPick.value[j].values[conta])
          }
          const halfHour = 30
          tableData.value.push({
            time: `${hour}:${halfHour}`,
            values,
            checked: false,
          })
        }
        conta += 1
      }
    }

    watch([dataComputedPick, dataComputedFacility], () => {
      generateTableData()
    })

    onMounted(() => {
      emit('refreshPicks')
    })

    const handleCheckRowClick = () => {
      const rows = document.querySelectorAll('tr')
      const cells = rows[0].querySelectorAll('th')
      cells.forEach(col => {
        const chkcol = col.querySelector('input[type="checkbox"]')
        if (chkcol !== null) {
          if (chkcol.checked) {
            chkcol.click()
            chkcol.click()
          }
        }
      })
    }

    const handleCheckboxClick = async (e, event) => {
      const rows = document.querySelectorAll('tr')
      let isDisable = false
      if (!event.target.checked) {
        isDisable = true
      }
      const colchk = Number(e)
      let conta = 0
      let primero = ''
      let primeraHora = ''
      const peticiones = []
      rows.forEach(async (row, rowIndex) => {
        if (rowIndex > 0) { // Ignorar la primera fila de encabezados
          const cells = row.querySelectorAll('td')
          const checkboxCell = cells[0]
          const checkbox = checkboxCell.querySelector('input[type="checkbox"]')
          const shouldShow = checkbox.checked
          if (shouldShow && !isDisable) {
            if (primero === '') {
              primero = cells[colchk + 1]
              primeraHora = cells[1].innerHTML
            } else {
              cells[colchk + 1].style.display = 'none'
            }
            conta += 1
          } else if (!isDisable) {
            if (conta > 0) {
              primero.rowSpan = conta
              const filter = {
                start_time: primeraHora,
                end_time: cells[1].innerHTML,
                weekday: colchk - 1,
                facility_id: 1,
                row: rowIndex - conta,
                col: colchk - 1,
              }
              peticiones.push(filter)
            }
            conta = 0
            primero = ''
          } else {
            if (cells[colchk + 1].style.display === 'none') {
              cells[colchk + 1].style.display = ''
            }
            cells[colchk + 1].rowSpan = 1
          }
        }
      })

      peticiones.forEach(async peticion => {
        try {
          const respPicks = await getAllPicks(peticion)
          peticiones.respuesta = respPicks.data
          const rowAct = rows[peticion.row]
          const cellsAct = rowAct.querySelectorAll('td')
          const cambio = cellsAct[peticion.weekday + 2].querySelector('span')
          cambio.innerHTML = respPicks.data.total_pĺayers
        } catch (error) {
          console.error('Error al obtener datos de', error)
        }
      })
    }

    return {
      dateStr,
      days,
      tableData,
      itemsm,
      getRandomInt,
      generateTableData,
      handleCheckRowClick,
      handleCheckboxClick,

      fieldsOptions,
      dataComputedPick,
      dataComputedGroup,
      dataComputedFacility,
      isReservationNewOpen,
      reservationNewData,
      openCloseNew,
      onClickList,

      // icons
      icons: {
        mdiDotsVertical,
      },
    }
  },
}
</script>

<style>
table {
  border-collapse: collapse;
}

table,
th,
td {
  border: 1px solid black;
  padding: 5px;
  text-align: center;
}

th {
  background-color: lightgray;
}

input[type="checkbox"] {
  transform: scale(1.5);
  margin-right: 5px;
}

table {
  margin-top: 20px;
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid #ddd;
  border-radius: 10px;
  width: 100%;
}

th {
  background-color: black;
  color: white;
}

th:first-child {
  border-radius: 10px 0 0 0;
}

th:last-child {
  border-radius: 0 10px 0 0;
}

th, td {
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 5px;
  text-align: center;
}

td:last-child {
  border-right: none;
}

tr:last-child td {
  border-bottom: none;
  border-radius: 0 0 10px 10px;
}
</style>
