<template>
  <div>
    <v-card
      flat
      class="pa-3 mt-2"
    >
      <v-container class="bg-surface-variant">
        <v-row no-gutters>
          <v-col
            cols="12"
            sm="6"
          >
            <v-sheet class="ma-2 pa-2">
            </v-sheet>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            style="margin-bottom: auto; margin-top: auto; text-align: center;"
          >
            <div style="display: flex; flex-direction: row;">
              <v-btn
                color="secondary"
                @click="modalOpen =!modalOpen"
              >
                {{ "Selected Month" }}
              </v-btn>
              <v-btn-toggle
                mandatory
              >
                <v-btn @click="changeDate('c')">
                  {{ "CURRENT" }}
                </v-btn>

                <v-btn @click="changeDate('p')">
                  {{ t('tooltip.prev') }}
                </v-btn>

                <v-btn @click="changeDate('n')">
                  {{ t('tooltip.next') }}
                </v-btn>
              </v-btn-toggle>
            </div>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            cols="12"
            sm="6"
          >
            <v-sheet class="ma-2 pa-2">
            </v-sheet>
          </v-col>
          <v-col
            cols="12"
            sm="2"
            style="margin-bottom: auto; margin-top: auto; text-align: center;"
          >
            <v-container class="bg-surface-variant">
              <v-row no-gutters>
                <v-col
                  cols="12"
                  sm="12"
                  style="margin-bottom: auto; margin-top: auto; text-align: center;"
                >
                  {{ dataComputed.prev_month_label }}
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <v-col
            cols="12"
            sm="1"
            style="text-align: center; margin-bottom: auto; margin-top: auto;"
          >
            <v-container class="bg-surface-variant">
              <v-row no-gutters>
                <v-col
                  cols="12"
                  sm="12"
                  style="margin-bottom: auto; margin-top: auto; text-align: center;"
                >
                  {{ `Month over Month ` }}
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <v-col
            cols="12"
            sm="2"
            style="text-align: center; margin-bottom: auto; margin-top: auto;"
          >
            {{ dataComputed.month_label }}
          </v-col>
          <v-col
            cols="12"
            sm="1"
          >
            <v-container class="bg-surface-variant">
              <v-row no-gutters>
                <v-col
                  cols="12"
                  sm="12"
                  style="margin-bottom: auto; margin-top: auto; text-align: center;"
                >
                  {{ `Month over Month ` }}
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
        <v-row
          no-gutters
          style="border-top: 1px bisque solid; padding-top: 10px;"
        >
          <v-col
            cols="12"
            sm="6"
            style="vertical-align: middle; padding-left: 10px; font-weight: bold; font-size: large;"
          >
            News groups
          </v-col>
          <v-col
            cols="12"
            sm="2"
            style="vertical-align: middle; padding-left: 10px; font-weight: bold; font-size: large; text-align: center;"
          >
            {{ dataComputed.prev_new_groups_qty }}
          </v-col>
          <v-col
            cols="12"
            sm="1"
            :style="{ 'vertical-align': 'middle', 'padding-left': '10px', 'font-weight': 'bold', 'font-size': 'medium', 'text-align': 'center', 'color': dataComputed.diff_prev_new_groups_qty >0 ? 'green' : 'red'}"
          >
            {{ typeof dataComputed.diff_prev_new_groups_qty != "undefined" ? (`${dataComputed.diff_prev_new_groups_qty > 0 ? '+' : ''}${(dataComputed.diff_prev_new_groups_qty*100).toFixed(2)}%`) : '' }}
          </v-col>
          <v-col
            cols="12"
            sm="2"
            style="vertical-align: middle; padding-left: 10px; font-weight: bold; font-size: large; text-align: center;"
          >
            {{ dataComputed.new_groups_qty }}
          </v-col>
          <v-col
            cols="12"
            sm="1"
            :style="{ 'vertical-align': 'middle', 'padding-left': '10px', 'font-weight': 'bold', 'font-size': 'medium', 'text-align': 'center', 'color': dataComputed.diff_new_groups_qty >0 ? 'green' : 'red'}"
          >
            {{ typeof dataComputed.diff_new_groups_qty != "undefined" ? `${dataComputed.diff_new_groups_qty > 0 ? '+' : ''}${(dataComputed.diff_new_groups_qty*100).toFixed(2)}%` : '' }}
          </v-col>
        </v-row>
        <v-row
          no-gutters
          style="border-top: 1px bisque solid; padding-top: 10px;"
        >
          <v-col
            cols="12"
            sm="6"
            style="vertical-align: middle; padding-left: 10px; font-weight: bold; font-size: large;"
          >
            Number of groups
          </v-col>
          <v-col
            cols="12"
            sm="2"
            style="vertical-align: middle; padding-left: 10px; font-weight: bold; font-size: large; text-align: center;"
          >
            {{ dataComputed.prev_groups_qty }}
          </v-col>
          <v-col
            cols="12"
            sm="1"
            :style="{ 'vertical-align': 'middle', 'padding-left': '10px', 'font-weight': 'bold', 'font-size': 'medium', 'text-align': 'center', 'color': dataComputed.diff_prev_groups_qty >0 ? 'green' : 'red'}"
          >
            {{ typeof dataComputed.diff_prev_groups_qty != "undefined" ? (`${dataComputed.diff_prev_groups_qty > 0 ? '+' : ''}${(dataComputed.diff_prev_groups_qty*100).toFixed(2)}%`) : '' }}
          </v-col>
          <v-col
            cols="12"
            sm="2"
            style="vertical-align: middle; padding-left: 10px; font-weight: bold; font-size: large; text-align: center;"
          >
            {{ dataComputed.groups_qty }}
          </v-col>
          <v-col
            cols="12"
            sm="1"
            :style="{ 'vertical-align': 'middle', 'padding-left': '10px', 'font-weight': 'bold', 'font-size': 'medium', 'text-align': 'center', 'color': dataComputed.diff_groups_qty >0 ? 'green' : 'red'}"
          >
            {{ typeof dataComputed.diff_groups_qty != "undefined" ? (`${dataComputed.diff_groups_qty > 0 ? '+' : ''}${(dataComputed.diff_groups_qty*100).toFixed(2)}%`) : '' }}
          </v-col>
        </v-row>
        <v-row
          no-gutters
          style="border-top: 1px bisque solid; padding-top: 10px;"
        >
          <v-col
            cols="12"
            sm="6"
            style="vertical-align: middle; padding-left: 10px; font-weight: bold; font-size: large;"
          >
            Total Number of players
          </v-col>
          <v-col
            cols="12"
            sm="2"
            style="vertical-align: middle; padding-left: 10px; font-weight: bold; font-size: large; text-align: center;"
          >
            {{ dataComputed.prev_players_qty }}
          </v-col>
          <v-col
            cols="12"
            sm="1"
            :style="{ 'vertical-align': 'middle', 'padding-left': '10px', 'font-weight': 'bold', 'font-size': 'medium', 'text-align': 'center', 'color': dataComputed.diff_prev_players_qty >0 ? 'green' : 'red'}"
          >
            {{ typeof dataComputed.diff_prev_players_qty != "undefined" ? (`${dataComputed.diff_prev_players_qty > 0 ? '+' : ''}${(dataComputed.diff_prev_players_qty*100).toFixed(2)}%`) : '' }}
          </v-col>
          <v-col
            cols="12"
            sm="2"
            style="vertical-align: middle; padding-left: 10px; font-weight: bold; font-size: large; text-align: center;"
          >
            {{ dataComputed.players_qty }}
          </v-col>
          <v-col
            cols="12"
            sm="1"
            :style="{ 'vertical-align': 'middle', 'padding-left': '10px', 'font-weight': 'bold', 'font-size': 'medium', 'text-align': 'center', 'color': dataComputed.diff_players_qty >0 ? 'green' : 'red'}"
          >
            {{ typeof dataComputed.diff_players_qty != "undefined" ? (`${dataComputed.diff_players_qty > 0 ? '+' : ''}${(dataComputed.diff_players_qty*100).toFixed(2)}%`) : '' }}
          </v-col>
        </v-row>
        <v-row
          no-gutters
          style="border-top: 1px bisque solid; padding-top: 10px;"
        >
          <v-col
            cols="12"
            sm="6"
            style="vertical-align: middle; padding-left: 10px; font-weight: bold; font-size: large;"
          >
            Average Number of players per Group
          </v-col>
          <v-col
            cols="12"
            sm="2"
            style="vertical-align: middle; padding-left: 10px; font-weight: bold; font-size: large; text-align: center;"
          >
            {{ dataComputed.prev_avg_play_group }}
          </v-col>
          <v-col
            cols="12"
            sm="1"
            :style="{ 'vertical-align': 'middle', 'padding-left': '10px', 'font-weight': 'bold', 'font-size': 'medium', 'text-align': 'center', 'color': dataComputed.diff_prev_avg_play_group >0 ? 'green' : 'red'}"
          >
            {{ typeof dataComputed.diff_prev_avg_play_group != "undefined" ? (`${dataComputed.diff_prev_avg_play_group > 0 ? '+' : ''}${(dataComputed.diff_prev_avg_play_group*100).toFixed(2)}%`) : '' }}
          </v-col>
          <v-col
            cols="12"
            sm="2"
            style="vertical-align: middle; padding-left: 10px; font-weight: bold; font-size: large; text-align: center;"
          >
            {{ dataComputed.avg_play_group }}
          </v-col>
          <v-col
            cols="12"
            sm="1"
            :style="{ 'vertical-align': 'middle', 'padding-left': '10px', 'font-weight': 'bold', 'font-size': 'medium', 'text-align': 'center', 'color':( dataComputed.diff_avg_play_group >0 ? 'green' : 'red')}"
          >
            {{ typeof dataComputed.diff_avg_play_group != "undefined" ? (`${dataComputed.diff_avg_play_group > 0 ? '+' : ''}${(dataComputed.diff_avg_play_group*100).toFixed(2)}%`) : '' }}
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-dialog
      v-model="modalOpen"
      persistent
      max-width="400px"
    >
      <v-card>
        <v-card-text>
          <!-- v-date-picker dentro de la ventana modal -->
          <v-date-picker
            v-model="changedDateTab"
            type="month"
            format="MMMM YYYY"
          ></v-date-picker>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="changeDate(null)"
          >
            Cambiar
          </v-btn>
          <v-btn
            color="secondary"
            @click="modalOpen =!modalOpen"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable object-curly-newline */
import { mdiAlertOutline, mdiCloudUploadOutline, mdiCamera, mdiMenuDown } from '@mdi/js'
import { ref, computed } from '@vue/composition-api'
import { required, emailValidator, between, integerValidator } from '@core/utils/validation'
import { useUtils } from '@core/libs/i18n'
import { avatarText } from '@core/utils/filter'

export default {
  props: {
    option: {
      type: Number,
      default: 0,
    },
    dataParams: {
      type: Object,
      default: () => {},
    },
    dataTransaction: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const form = ref(null)
    const fechaAct = new Date()
    const mesI = fechaAct.getMonth() + 1
    const annoI = fechaAct.getFullYear()
    const changedDateTab = ref(`${annoI}-${mesI}`)
    const { t } = useUtils()
    const modalOpen = ref(false)
    const dataComputed = computed(() => (props.dataParams ? props.dataParams : {}))

    const changeDate = valor => {
      if (valor !== undefined && valor !== null) {
        if (valor === 'c') {
          const dateN = new Date()
          const year = dateN.getFullYear()
          const month = dateN.getMonth() + 1
          changedDateTab.value = `${year}-${month.toString().padStart(2, '0')}`
        } else if (valor === 'n') {
          const dateN = new Date(`${changedDateTab.value}-01`)
          dateN.setMonth(dateN.getMonth() + 2)
          const year = dateN.getFullYear()
          const month = dateN.getMonth() + 1
          changedDateTab.value = `${year}-${month.toString().padStart(2, '0')}`
        } else if (valor === 'p') {
          const dateP = new Date(`${changedDateTab.value}-01`)
          dateP.setMonth(dateP.getMonth() - 1)
          const year = dateP.getFullYear()
          const month = dateP.getMonth() + 1
          changedDateTab.value = `${year}-${month.toString().padStart(2, '0')}`
        }
      } else {
        modalOpen.value = !modalOpen.value
      }
      emit('change', changedDateTab.value)
    }

    return {
      // data
      form,

      modalOpen,
      changedDateTab,

      // computed
      dataComputed,

      // methods
      avatarText,
      changeDate,

      // validations
      required,
      emailValidator,
      between,
      integerValidator,

      // i18n
      t,

      // icons
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
        mdiCamera,
        mdiMenuDown,
      },
    }
  },
}
</script>

<style lang="scss" scope>
div .v-input__prepend-outer {
  margin: 0px 8px 0px 0px !important;
}
</style>
