import { ref, computed, watch } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import useCryptoJs from '@core/utils/useCryptoJs'
// eslint-disable-next-line object-curly-newline
import { getFieldsByFacility, getAllPicks, getMetrics, getFacilitiesByGroup, getGroupsActive } from '@api'

// import store from '@/store'

export default function useMetrics() {
  const { t } = useUtils()
  const videoListTable = ref([])
  const fieldOptions = ref([])
  const facilitiesOptions = ref([])
  const groupsOptions = ref([])
  const metricsValues = ref({})
  const picksValues = ref([])

  const { userData } = useCryptoJs()

  const computedTableColumns = computed(() => [
    { text: t('fields.field').toUpperCase(), value: 'name' },
    { text: t('facilities.facility').toUpperCase(), value: 'facility.name' },
    { text: t('sports.sport').toUpperCase(), value: 'sport.name', sortable: false },
    { text: t('fields.pricing').toUpperCase(), value: 'pricing_type_str', sortable: false },
    { text: t('fields.hourly_pricing').toUpperCase(), value: 'hourly_pricing', sortable: false },

    { text: t('users.users').toUpperCase(), value: 'fullname' },
    { text: t('facilities.facility').toUpperCase(), value: 'email' },
    { text: t('users.role').toUpperCase(), value: 'role', sortable: false },
    { text: t('facilities.facility').toUpperCase(), value: 'facility.name', sortable: false },
    { text: t('status.status').toUpperCase(), value: 'status', sortable: false },
    {
      text: t('tooltip.actions').toUpperCase(),
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ])

  const totalVideoListTable = ref(null)

  const searchQuery = ref('')
  const roleFilter = ref(null)

  // const facilityFilter = ref(null)
  const facilityFilter = ref('')
  const selectedDate = ref(null)
  const statusFilter = ref(null)
  const fieldFilter = ref(null)
  const groupFilter = ref(null)

  const fechaAct = new Date()
  const mesI = fechaAct.getMonth() + 1
  const annoI = fechaAct.getFullYear()
  const changedDate = ref(`${annoI}-${mesI}`)

  const dateFilter = ref(
    new Date().toLocaleString('sv-SE', {
      timeZone: 'America/New_York',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }),
  )

  const now = ref(new Date())
  const nowDate = ref(
    new Date(now.value).toLocaleString('sv-SE', {
      timeZone: 'America/New_York',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }),
  )

  const min = ref(+new Date() - 12096e5)
  const minDate = ref(
    new Date(min.value).toLocaleString('sv-SE', {
      timeZone: 'America/New_York',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }),
  )

  const max = ref(+new Date() + 12096e5)
  const maxDate = ref(
    new Date(max.value).toLocaleString('sv-SE', {
      timeZone: 'America/New_York',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }),
  )

  const loading = ref(false)
  const options = ref({
    sortBy: ['name'],
    sortDesc: [true],
  })
  const userTotalLocal = ref([])
  const selectedRows = ref([])
  const page = ref(1)
  const perPage = ref(100)

  const getVisibleList = () => {
    if (videoListTable.value.length) {
      if (videoListTable.value.length > perPage.value) return videoListTable.value.slice((page.value - 1) * perPage.value, page.value * perPage.value)

      return videoListTable.value
    }

    return []
  }

  const visibleVideoListTable = computed(() => getVisibleList())

  const lengthPagination = () => Math.ceil(totalVideoListTable.value / perPage.value)

  const fetchPicks = async () => {
    const filter = {
      start_time: '',
      end_time: '',
      weekday: 0,
    }
    filter.facility_id = facilityFilter.value
    const respPicks = await getAllPicks(filter)
    picksValues.value = respPicks.data
  }

  // fetch data
  const fetchMetrics = async () => {
    const fechaActual = new Date()
    let mes = ''
    let anno = ''
    if (changedDate.value === undefined || changedDate.value === null) {
      mes = fechaActual.getMonth() + 1
      anno = fechaActual.getFullYear()
    } else {
      mes = changedDate.value.substring(5, 7)
      anno = changedDate.value.substring(0, 4)
    }
    const respMetrics = await getMetrics(facilityFilter.value, mes, anno)
    metricsValues.value = respMetrics.data
  }

  const fetchGroups = async () => {
    const groups = await getGroupsActive()
    groupsOptions.value = groups.data
    if (groupsOptions.value.length > 0) groupFilter.value = groupsOptions.value[0].id
  }

  const fetchFacilities = async groupId => {
    if (groupId) {
      const facilities = await getFacilitiesByGroup(groupId)
      facilitiesOptions.value = facilities.data
      if (facilitiesOptions.value.length === 1) facilityFilter.value = facilitiesOptions.value[0].id
    } else if (userData.value && userData.value.role !== 'A') {
      const facilities = await getFacilitiesByGroup(userData.value.group_id)
      facilitiesOptions.value = facilities.data
      if (facilitiesOptions.value.length === 1) facilityFilter.value = facilitiesOptions.value[0].id
    } else {
      facilitiesOptions.value = []
    }
  }

  const fetchFields = async facilityId => {
    let fields = null
    if (userData.value && userData.value.role !== 'A') {
      // fields = await getFieldsByFacility(userData.value.facility_id)

      if (facilityId) {
        fields = await getFieldsByFacility(facilityId)
      }
    } else if (facilityId) fields = await getFieldsByFacility(facilityId)
    else fields = [] // await getFields()

    if (fields && fields.ok) {
      fieldOptions.value = fields.data
      if (fieldOptions.value.length > 0) fieldFilter.value = fieldOptions.value[0].id
    } else fieldOptions.value = []
  }

  watch([groupFilter], async () => {
    facilitiesOptions.value = []
    facilityFilter.value = 0
    await fetchFacilities(groupFilter.value)
  })

  watch([facilityFilter, changedDate], async () => {
    fetchMetrics()
    fetchPicks()
  })

  return {
    groupsOptions,
    userData,
    videoListTable,
    computedTableColumns,
    searchQuery,
    roleFilter,
    facilityFilter,
    selectedDate,
    statusFilter,
    fieldFilter,
    groupFilter,
    dateFilter,
    now,
    min,
    max,
    nowDate,
    minDate,
    maxDate,
    loading,
    options,
    userTotalLocal,
    selectedRows,
    facilitiesOptions,
    fieldOptions,
    visibleVideoListTable,
    page,
    perPage,
    metricsValues,
    picksValues,
    changedDate,

    t,
    fetchMetrics,
    fetchFields,
    fetchFacilities,
    fetchGroups,
    lengthPagination,
    fetchPicks,
  }
}
