<template>
  <v-dialog
    v-model="isOpen"
    max-width="800"
    @click:outside="$emit('update:is-open',false)"
  >
    <v-card class="user-card-detail pa-sm-10 pa-3">
      <v-card-title class="user-edit-title justify-center text-h5">
        {{ t('reservations.new_reservation') }}
      </v-card-title>
      <!-- <v-card-text class="text-center mt-n2">
        {{ t('reservations.new_reservation') }}
      </v-card-text> -->

      <v-card-text class="mt-5">
        <v-form
          ref="form"
          class="multi-col-validation"
        >
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-select
                v-model="reservationData.field_id"
                dense
                outlined
                :label="t('fields.field')"
                :items="fieldsOptions"
                item-text="name"
                item-value="id"
                :append-icon="null"
              ></v-select>
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="reservationData.reservation_date"
                :label="t('transactions.date')"
                color="primary"
                outlined
                dense
                persistent-hint
                :prepend-icon="icons.mdiCalendarBlankOutline"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="reservationData.start_time"
                :label="t('videos.time')"
                color="primary"
                outlined
                dense
                persistent-hint
                :prepend-icon="icons.mdiClockTimeFourOutline"
                readonly
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-select
                v-model="reservationData.reservation_time"
                dense
                outlined
                :label="t('video_details.duration')"
                :items="minuteOptions"
                item-text="text"
                item-value="value"
                :prepend-icon="icons.mdiTimerOutline"
                :rules="[required]"
              ></v-select>
            </v-col>

            <v-col
              cols="12"
              md="6"
              class="d-flex align-center"
            >
              <v-checkbox
                v-model="reservationData.include_video"
                :label="t('reservations.include_video')"
                true-value="Y"
                false-value="N"
                color="secondary"
                dense
                class="ma-0 pa-0"
                hide-details
              ></v-checkbox>
            </v-col>

            <v-col
              cols="12"
              class="d-flex justify-center"
            >
              <v-btn
                color="primary"
                class="me-3"
                @click="saveAction"
              >
                {{ t('tooltip.save') }}
              </v-btn>
              <v-btn
                color="secondary"
                outlined
                @click="() => {
                  cancelAction()
                  $emit('update:is-open', false)
                }"
              >
                {{ t('tooltip.cancel') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable object-curly-newline */
import { ref, computed, watch } from '@vue/composition-api'
import { mdiClockTimeFourOutline, mdiTimerOutline, mdiCalendarBlankOutline } from '@mdi/js'
import { useUtils } from '@core/libs/i18n'
import { createReservation } from '@api'
import { error, success } from '@core/utils/toasted'
import useCryptoJs from '@core/utils/useCryptoJs'
import { required, emailValidator, integerValidator } from '@core/utils/validation'

export default {
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    date: {
      type: String,
      default: '',
      required: true,
    },
    dataParams: {
      type: Object,
      default: () => {},
      required: true,
    },
    fields: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()
    const form = ref(null)
    const { userData } = useCryptoJs()

    const computedDataParams = computed(() => props.dataParams)
    const computedDate = computed(() => props.date)
    const fieldsOptions = computed(() => props.fields)
    const computedIsAdmin = computed(() => userData.value.role === 'A')
    const computedIsOpen = computed({
      set: val => emit('update:is-open', val),
      get: () => props.isOpen,
    })

    const minuteOptions = ref([
      { text: '60 Min', value: 60 },
      { text: '90 Min', value: 90 },
      { text: '120 Min', value: 120 },
      { text: '150 Min', value: 150 },
    ])

    const initialData = {
      group_id: null,
      facility_id: null,
      field_id: null,
      circle_id: null,
      user_console_id: null,
      reservation_type: null,
      reservation_date: computedDate.value,
      start_time: null,
      reservation_time: null,
      include_video: 'Y',
    }

    const reservationData = ref(initialData)

    const postData = ref({
      group_id: null,
      facility_id: null,
      field_id: null,
      circle_id: null,
      user_console_id: null,
      reservation_type: null,
      reservation_date: null,
      start_time: null,
      reservation_time: null,
      include_video: 'Y',
    })

    watch([computedIsOpen], () => {
      if (computedIsOpen.value) {
        reservationData.value = computedDataParams.value
        reservationData.value.reservation_date = computedDate.value
        reservationData.value.include_video = 'Y'
      }
    })

    const cancelAction = () => {
      form.value.reset()
      reservationData.value = Object.assign(initialData, {
        circle_id: null,
        reservation_type: null,
        reservation_time: null,
        include_video: 'Y',
      })
    }

    const saveAction = async () => {
      if (form.value.validate()) {
        postData.value = Object.assign(initialData, {
          group_id: reservationData.value.group_id,
          facility_id: reservationData.value.facility_id,
          field_id: reservationData.value.field_id,
          circle_id: reservationData.value.circle_id,
          user_console_id: userData.value.username,
          reservation_type: reservationData.value.reservation_type,
          reservation_date: reservationData.value.reservation_date,
          start_time: reservationData.value.start_time,
          reservation_time: reservationData.value.reservation_time,
          include_video: reservationData.value.include_video,
        })

        const resp = await createReservation(postData.value, reservationData.value.facility_id)

        let message = ''

        if (resp.ok) {
          message = resp.message
          emit('refreshEvents')
          cancelAction()
          success(message)
        } else {
          message = resp.message.text
          error(message)
        }
        computedIsOpen.value = false
        form.value.reset()
        reservationData.value = Object.assign(initialData, {
          circle_id: null,
          reservation_type: null,
          reservation_time: null,
          include_video: 'Y',
        })
      }
    }

    return {
      form,
      fieldsOptions,
      minuteOptions,
      reservationData,
      postData,
      userData,

      // computed
      computedIsOpen,
      computedIsAdmin,
      computedDataParams,

      // i18n
      t,

      // validations
      required,
      emailValidator,
      integerValidator,

      // Methods
      saveAction,
      cancelAction,

      // icons
      icons: {
        mdiClockTimeFourOutline,
        mdiTimerOutline,
        mdiCalendarBlankOutline,
      },
    }
  },
}
</script>
